import { useRef, useState } from "react"
import { createPortal } from "react-dom"

//components
import RedditTextField from "components/misc/RedditTextField"
import CustomerInput from "./CustomerInput"
import AddOrEditStore from "components/pages/customers/partials/AddOrEditStore"

//MUI components
import { Tooltip, Select, MenuItem, Button } from "@material-ui/core"
import Autocomplete from "components/misc/Autocomplete"
import CustomerInputMain from "./CustomerInputMain"

//images
import initialCleintIcon from "assets/img/documents/icons/initial-client.png"
import checkIcon from "assets/img/documents/icons/check.png"
import warningIcon from "assets/img/documents/icons/warning.png"
import loadingImage from "assets/img/loader-2.svg"
import storeAddColoredIcon from "assets/img/documents/icons/store-add-colored.png"

//providers
import { useAppContext } from "providers/App"

//hooks
import useSetActiveTradeObject from "hooks/useSetActiveTradeObject"

function CustomerDeepData({
  showAllClientFields,
  data,
  validations,
  handleCustomerChange,
  handleFocusToChangeBg,
  focusedInputName,
  handleCustomerEikChange,
  setState,
  state,
  isVatNumberValid,
  handleInputChange,
  handleCustomerSettlementChange,
  handleCheckCustomerVatNumber,
  colNameRef,
  handleShowClientHiddenFields,
  tradeRegistryReqUrl,
  customersFindReqUrl,
  noCompany = false,
  noTradeObject = false,
  handleChangeCustomerTradeObject,
}) {
  const { handleSuccess } = useAppContext()

  const { isLoading, setActiveTradeObject } = useSetActiveTradeObject()

  const [newlyAddedTradeObjects, setNewlyAddedTradeObjects] = useState([])

  const addOrEidtStoreRef = useRef(null)

  function handleChangeTradeObject(e) {
    const { value } = e.target

    if (!value) return

    handleChangeCustomerTradeObject?.(
      [...(data?.customer?.addresses || []), ...newlyAddedTradeObjects].find(
        (address) => address.id === e.target.value
      )
    )

    setActiveTradeObject(data?.customer?.id, value)
  }

  function handleAddTradeObject() {
    const modal = addOrEidtStoreRef.current

    modal.add()

    modal.onSuccess((data) => {
      handleSuccess("Търговския обект беше добавен успешно!")
      setNewlyAddedTradeObjects([...newlyAddedTradeObjects, data])
    })
  }

  return showAllClientFields() ? (
    <>
      <div className="col with-bg">
        <div
          className={`row ${
            focusedInputName === "customerinfo[name]" ? "selected" : ""
          }`}
        >
          <div className="col">Име:</div>
          <div className="col">
            <CustomerInput
              companyId={data?.company?.id}
              noCompany={noCompany}
              tradeRegistryReqUrl={tradeRegistryReqUrl}
              customersFindReqUrl={customersFindReqUrl}
              name="customerinfo[name]"
              value={data?.customer?.info?.name || data?.customer?.name}
              onChange={handleCustomerChange}
              onInputChange={handleCustomerChange}
              error={Boolean(validations && validations.customerinfo?.name)}
              helperText={
                validations &&
                validations.customerinfo &&
                validations.customerinfo.name &&
                (validations.customerinfo.name[0] ||
                  validations.customerinfo.name)
              }
              onFocus={() => {
                // background-color: #E4F2FF;
                handleFocusToChangeBg("customerinfo[name]")
              }}
              onBlur={() => {
                handleFocusToChangeBg(null)
              }}
            />
            <input
              type="hidden"
              name="customer[id]"
              value={data?.customer?.id || null}
            />
          </div>
        </div>
        <div
          className={`row double ${
            focusedInputName === "customerinfo[eikegn]" ||
            focusedInputName === "customerinfo[vat_number]"
              ? "selected"
              : ""
          }`}
        >
          <div className="col">
            <div className="row">
              <div className="col">ЕИК:</div>
              <div className="col">
                <CustomerInput
                  companyId={data?.company?.id}
                  name="customerinfo[eikegn]"
                  noCompany={noCompany}
                  value={data?.customerinfo?.eikegn || null}
                  searchParamName="eikegn"
                  onChange={handleCustomerEikChange}
                  tradeRegistryReqUrl={tradeRegistryReqUrl}
                  customersFindReqUrl={customersFindReqUrl}
                  onInputChange={(e) => {
                    handleCustomerEikChange(e)
                    setState((prev) => ({
                      ...prev,
                      data: {
                        ...prev.data,
                        customerinfo: {
                          ...prev.data.customerinfo,
                          eikegn: e,
                        },
                      },
                    }))
                  }}
                  onFocus={() => {
                    // background-color: #E4F2FF;
                    handleFocusToChangeBg("customerinfo[eikegn]")
                  }}
                  onBlur={() => {
                    handleFocusToChangeBg(null)
                  }}
                  error={Boolean(
                    validations && validations.customerinfo?.eikegn
                  )}
                  helperText={
                    validations &&
                    validations.customerinfo &&
                    validations.customerinfo.eikegn &&
                    (validations.customerinfo.eikegn[0] ||
                      validations.customerinfo.eikegn)
                  }
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className={`row ${
                focusedInputName === "customerinfo[vat_number]"
                  ? "selected"
                  : ""
              }`}
            >
              <div className="col">ИН ПО ЗДДС:</div>
              <div className="col">
                <div className="customer-input">
                  <Tooltip
                    title={
                      state.vatLoading
                        ? "Извършва се проверка..."
                        : data?.customerinfo?.vat_number?.length || 0
                        ? `Въведения ДДС номер е ${
                            isVatNumberValid().isValid ? "валиден" : "невалиден"
                          }!`
                        : "Въвеждане на ДДС номер за проверка"
                    }
                  >
                    <RedditTextField
                      margin="dense"
                      size="small"
                      fullWidth
                      // label="ИН по ЗДДС"
                      name="customerinfo[vat_number]"
                      value={data?.customerinfo?.vat_number || ""}
                      onChange={handleInputChange}
                      error={Boolean(
                        validations && validations.customerinfo?.vat_number
                      )}
                      helperText={
                        validations &&
                        validations.customerinfo &&
                        validations.customerinfo.vat_number &&
                        (validations.customerinfo.vat_number[0] ||
                          validations.customerinfo.vat_number)
                      }
                      InputLabelProps={{ shrink: true }}
                      onFocus={() => {
                        // background-color: #E4F2FF;
                        handleFocusToChangeBg("customerinfo[vat_number]")
                      }}
                      onBlur={() => {
                        handleFocusToChangeBg(null)
                      }}
                    />
                  </Tooltip>
                  {data?.customerinfo?.vat_number?.length || 0 ? (
                    isVatNumberValid().hasVat &&
                    !state.vatLoading &&
                    !state.renewCheckVat ? (
                      isVatNumberValid().isValid ? (
                        <Tooltip title="ДДС номера е валиден!">
                          <img src={checkIcon} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="ДДС номера е невалиден!">
                          <img src={warningIcon} />
                        </Tooltip>
                      )
                    ) : state.vatLoading ? (
                      <img src={loadingImage} />
                    ) : (
                      <Tooltip title="Проверка на ДДС номер">
                        <div
                          className="eu"
                          onClick={() => {
                            handleCheckCustomerVatNumber()
                          }}
                        ></div>
                      </Tooltip>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`row ${
            focusedInputName === "customeraddress[mol]" ? "selected" : ""
          }`}
        >
          <div className="col">МОЛ:</div>
          <div className="col">
            <RedditTextField
              size="small"
              margin="dense"
              fullWidth
              name="customeraddress[mol]"
              value={data?.customeraddress?.mol || ""}
              onChange={handleInputChange}
              error={Boolean(validations && validations.customeraddress?.mol)}
              helperText={
                validations &&
                validations.customeraddress &&
                validations.customeraddress.mol &&
                (validations.customeraddress.mol[0] ||
                  validations.customeraddress.mol)
              }
              onFocus={() => {
                // background-color: #E4F2FF;
                handleFocusToChangeBg("customeraddress[mol]")
              }}
              onBlur={() => {
                handleFocusToChangeBg(null)
              }}
            />
          </div>
        </div>
        <div
          className={`row ${
            focusedInputName === "customeraddress[settlement][name]"
              ? "selected"
              : ""
          }`}
        >
          <div className="col">Град:</div>
          <div className="col">
            <Autocomplete
              // inputPlaceholder="град"
              inputName={`customeraddress[settlement][name]`}
              inputIdName={`customeraddress[settlement][id]`}
              url="settlements/all"
              // params={{
              //     municipality_id: address.municipality?.id
              // }}
              selected={data?.customeraddress?.settlement || null}
              getOptionLabel={(data) => data?.name || ""}
              renderOption={(option) => {
                return [
                  option.name,
                  option.municipality
                    ? "общ. " + option.municipality.name
                    : null,
                  option.municipality && option.municipality.district
                    ? "обл. " + option.municipality.district.name
                    : null,
                ]
                  .filter((o) => {
                    return o !== null
                  })
                  .join(", ")
              }}
              error={Boolean(
                validations && validations.customeraddress?.settlement
              )}
              helperText={
                validations &&
                validations.customeraddress &&
                validations.customeraddress.settlement &&
                (validations.customeraddress.settlement[0] ||
                  validations.customeraddress.settlement)
              }
              onChange={handleCustomerSettlementChange}
              onInputChange={handleCustomerSettlementChange}
              onInputFocus={() => {
                // background-color: #E4F2FF;
                handleFocusToChangeBg("customeraddress[settlement][name]")
              }}
              onInputBlur={() => {
                handleFocusToChangeBg(null)
              }}
            />

            <input
              type="hidden"
              name="customeraddress[settlement][municipality_id]"
              value={data?.customeraddress?.settlement?.municipality_id || ""}
            />
          </div>
        </div>

        <div
          className={`row ${
            focusedInputName === "customeraddress[address]" ? "selected" : ""
          }`}
        >
          <div className="col">Адрес:</div>
          <div className="col">
            <RedditTextField
              size="small"
              margin="dense"
              fullWidth
              name="customeraddress[address]"
              value={data?.customeraddress?.address || ""}
              onChange={handleInputChange}
              error={Boolean(
                validations && validations.customeraddress?.address
              )}
              helperText={
                validations &&
                validations.customeraddress &&
                validations.customeraddress.address &&
                (validations.customeraddress.address[0] ||
                  validations.customeraddress.address)
              }
              onFocus={() => {
                // background-color: #E4F2FF;
                handleFocusToChangeBg("customeraddress[address]")
              }}
              onBlur={() => {
                handleFocusToChangeBg(null)
              }}
            />
          </div>
        </div>
      </div>
      {!noTradeObject ? (
        <div className="row trade-objects-select">
          <div className="col">Обект:</div>
          <div className={`col ${isLoading ? "disabled" : ""}`}>
            <Select
              value={data?.customeraddress?.id}
              MenuProps={{
                className: "trade-objects-select-menu",
              }}
              onChange={handleChangeTradeObject}
            >
              {[
                ...(data?.customer?.addresses || []),
                ...newlyAddedTradeObjects,
              ].map((address) => (
                <MenuItem value={address.id}>
                  <b>{address.name}</b>
                  <p>{address.full_address}</p>
                </MenuItem>
              ))}
              <Button onClick={handleAddTradeObject}>
                <img src={storeAddColoredIcon} alt="" />
                Добавяне на нов обект
              </Button>
            </Select>
            {createPortal(
              <AddOrEditStore
                ref={addOrEidtStoreRef}
                clientId={data?.customer?.id}
              />,
              document.body
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  ) : (
    <>
      <div className="row">
        <div className="col" data-name="name" ref={colNameRef}>
          <CustomerInputMain
            companyId={data?.company?.id}
            noCompany={noCompany}
            tradeRegistryReqUrl={tradeRegistryReqUrl}
            customersFindReqUrl={customersFindReqUrl}
            label="Търсене на клиент"
            name="customerinfo[name]"
            value={data?.customerinfo?.name || data?.customer?.name || null}
            onChange={handleCustomerChange}
            onInputChange={handleCustomerChange}
            error={Boolean(validations && validations.customerinfo?.name)}
            helperText={
              validations &&
              validations.customerinfo &&
              validations.customerinfo.name &&
              (validations.customerinfo.name[0] ||
                validations.customerinfo.name)
            }
            key={showAllClientFields()}
            handleShowClientHiddenFields={handleShowClientHiddenFields}
          />
          <input
            type="hidden"
            name="customer[id]"
            value={data?.customer?.id || null}
          />
        </div>
      </div>
      <div className="row initial-client">
        <img src={initialCleintIcon} />
        Въведете ЕИК / ЕГН или име на фирма, за да попълним автоматично другите
        данни на клиента
      </div>
    </>
  )
}

export default CustomerDeepData
