import { useEffect, useRef, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
// import ReactGA from 'react-ga'
import { createBrowserHistory } from "history"
import ReactGA from "react-ga4"

//helpers
import { useQuery } from './helpers/Url'

//pages
import Register from './components/pages/auth/register/Index'
import Verify from './components/pages/auth/Verify/Verify'
import VerifySuccess from './components/pages/auth/Verify/Success'
import VerifyFail from './components/pages/auth/Verify/Fail'
import ForgotPassword from './components/pages/auth/Reset/Index'
import ResetPassword from './components/pages/auth/Reset/Reset'
import Login from './components/pages/auth/Login'
import Logout from './components/pages/auth/Logout'

import IntegrationsOAuthCallback from './components/pages/settings/partials/integrations/OAuthCallback'
import IntegrationsConnectSlack from './components/pages/settings/partials/integrations/ConnectSlack'
import IntegrationsSlackCallback from './components/pages/settings/partials/integrations/SlackCallback'
import IntegrationsConnectMessenger from './components/pages/settings/partials/integrations/ConnectMessenger'
import IntegrationsSuccess from './components/pages/settings/partials/integrations/result/Success'
import IntegrationsFail from './components/pages/settings/partials/integrations/result/Fail'

import Customers from './components/pages/customers/Index'

import Subscriptions from './components/pages/subscriptions/Index'

import Documents from './components/pages/documents/Index'
import Import from './components/pages/documents/Import'

import PayOrders from './components/pages/payorders/Index'
import PayOrder from './components/pages/payorders/Document'

import CashOrders from './components/pages/cashorders/Index'
import CashOrder from './components/pages/cashorders/Document'

// import Categories from './components/pages/products/categories/Index'
// import Products from './components/pages/products/Index'

import Finances from './components/pages/finances/Index'

import Reports from './components/pages/reports/Index'
import ReportStorageAmountsByDepots from 'components/pages/reports/storage/amounts_by_depots/Index'

import Projects from './components/pages/projects/projects/Index'
import ProjectsTypes from './components/pages/projects/types/Index'
import ProjectsTeams from './components/pages/projects/teams/Index'

import Machines from './components/pages/machines/machines/Index'
import MachinesTypes from './components/pages/machines/types/Index'

import Tasks from './components/pages/tasks/tasks/Index'
import TasksTypes from './components/pages/tasks/types/Index'
import TasksStatuses from './components/pages/tasks/statuses/Index'

import Settings from './components/pages/settings/Index'

import Categories from './components/pages/categories/Index'
import Articles from './components/pages/articles/Index.jsx'
import ArticlesMassUpdate from './components/pages/articles/MassUpdate'
import ArticlesPrintList from './components/pages/articles/PrintList'

import StoreLoads from './components/pages/storeloads/Index'
import StoreMoves from './components/pages/storemoves/Index'
import StoreOuts from './components/pages/storeouts/Index'
import StoreOut from './components/pages/storeouts/StoreOut'
import Revisions from './components/pages/revisions/Index'
import Revision from './components/pages/revisions/Revision'

import Accounts from './components/pages/accounts/Index'
import Account from './components/pages/accounts/Account'

import Orders from './components/pages/orders/Index'

import PublicDocumentPreview from './components/pages/documents/partials/public_preview/Index'

import PaymentComplete from './components/pages/payments/Complete'
import PaymentCancel from './components/pages/payments/Cancel'

import CompanyDeleteRequestSuccess from './components/pages/companies/delete/Success'
import CompanyDeleteRequestFail from './components/pages/companies/delete/Fail'

import Dashboard from './components/pages/dashboard/Index'

import Sandbox from 'components/pages/sandbox/Index'

import MarketingCalendar from 'components/pages/marketing/calendar/Index'
import MarketingArchive from 'components/pages/marketing/archive/Index'
import MarketingDeals from 'components/pages/marketing/deals/Index'

import Complaints from 'components/pages/sales/complaints/Index'
import Recommendations from 'components/pages/sales/recommendations/Index'

import Communication from 'components/pages/communication/Index'


import e401 from './components/pages/errors/401'
import e404 from './components/pages/errors/404'

// middlewares
import Authed from './middlewares/Authed'
import Guest from './middlewares/Guest'
import Verified from './middlewares/Verified'
import NotVerified from './middlewares/NotVerified'
import Container from './Container'

//providers
import { useAuthDataContext } from './providers/Auth'
import { useLanguageContext } from './providers/Language'
import { useCurrencyContext } from './providers/Currency'
import { useAppContext } from 'providers/App'
import { useDocumentContext } from 'components/pages/documents/Provider'
import PublicPreviewProvider from 'components/pages/documents/partials/public_preview/PublicPreviewProvider'

//components
import ProtectedRoute from './components/ProtectedRoute'
import SnackBar from './components/misc/SnackBar'
import Update from './components/modals/Update'
import AppLoader from 'components/partials/AppLoader'
import Footer from 'components/partials/app/Footer'
import SharedModals from 'components/modals/Shared'
import AllSales from 'components/pages/sales/all/Index'

// style
// import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/app.scss'
import './assets/scss/modal-primary.scss'

const VERSION_CODE = '1.2.0'

function App() {
	const app = useAppContext()
	const auth = useAuthDataContext()
	const langs = useLanguageContext()
	const currency = useCurrencyContext()
	const documents = useDocumentContext()
	const history = useHistory()
	const location = useLocation()
	const query = useQuery()
	const container = Container.getInstance()

	const updateModalRef = useRef()

	const [booted, setBooted] = useState(false)
	const [bootedServices, setBootedServices] = useState({
		user: false,
		langs: false,
		currency: false,
		documentsCount: false,
	})

	container.setAuth(auth)
	container.setHistory(history)
	container.setLocation(location)
	container.setQuery(query)


	useEffect(() => {
		if (auth.isLogged()) {
			auth.loadData()
				.then(() => {
					makeBooted('user')
				})
		} else makeBooted('user')

		langs.loadData()
			.then(() => {
				makeBooted('langs')
			})

		currency.loadData()
			.then(() => {
				makeBooted('currency')
			})

		const contentEditableElements = document.querySelectorAll('[contenteditable]')

		if (contentEditableElements.length) {
			for (let i = 0; i < contentEditableElements.length; i++) contentEditableElements[i].setAttribute('contenteditable', 'false')
		}
	}, [])

	useEffect(() => {
		ReactGA.initialize([
			{
				trackingId: 'G-NFQK8QJ0LX',
				gaOptions: {
					userId: auth?.getUser()?.getId()
				}
			}
		])
		const currentHistory = createBrowserHistory()

		ReactGA.send({
			hitType: `${currentHistory.location.pathname}${history.location.search}`,
			page: `${currentHistory.location.pathname}${history.location.search}`
		})
		documents.loadDocumentsCount()
	}, [history.location])

	useEffect(() => {
		if (auth.isLogged()) {
			documents.loadDocumentsCount()
				.then(() => {
					makeBooted('documentsCount')
				})
		} else {
			makeBooted('documentsCount')
		}
	}, [auth.isLogged()])

	useEffect(() => {
		const all = Object.values(bootedServices).length
		const booted = Object.values(bootedServices).filter(booted => booted === true).length

		setBooted(all === booted)
	}, [bootedServices])

	useEffect(() => {
		if (booted && auth.isLogged()) {
			if (checkForNewUpdatesInLocalStorage()) updateModalRef.current?.open()
		}
	}, [booted, auth.isLogged()])

	const makeBooted = (service, booted = true) => {
		setBootedServices(prev => ({
			...prev,
			[service]: booted
		}))
	}

	function checkForNewUpdatesInLocalStorage() {
		if (localStorage.getItem('versionCode') !== VERSION_CODE) {
			return true
		}

		return false
	}

	if (!booted) {
		return <AppLoader />
	}

	const routes = (
		<Route render={({ location }) => (
			<Switch location={location}>
				<Route path="/" exact render={() => <Redirect to="/dashboard" />} />

				<ProtectedRoute path="/login" exact component={Login} />
				<ProtectedRoute path="/register" middleware={Guest} exact component={Register} />
				<ProtectedRoute path="/verify" exact middleware={[Authed, NotVerified]} component={Verify} />
				<ProtectedRoute path="/verify/success" exact component={VerifySuccess} />
				<ProtectedRoute path="/verify/fail" exact component={VerifyFail} />
				<ProtectedRoute path="/password/forgot" exact middleware={Guest} component={ForgotPassword} />
				<ProtectedRoute path="/password/reset" exact middleware={Guest} component={ResetPassword} />
				<ProtectedRoute path="/logout" exact middleware={Authed} component={Logout} />

				<ProtectedRoute path="/dashboard" exact middleware={[Authed, Verified]} module="documents" component={Dashboard} />

				<ProtectedRoute path="/customers" exact middleware={[Authed, Verified]} component={Customers} />

				<ProtectedRoute path="/subscriptions/:tab" exact middleware={[Authed, Verified]} component={Subscriptions} />

				{/* <ProtectedRoute path="/products/categories" exact middleware={[Authed, Verified]} component={Categories} /> */}
				{/* <ProtectedRoute path="/products" exact middleware={[Authed, Verified]} component={Products} /> */}

				<ProtectedRoute path="/invoices" exact middleware={[Authed, Verified]} module="documents" component={Documents} />
				{/* <ProtectedRoute path="/notes" exact middleware={[Authed, Verified]} module="documents" component={Documents} />
				<ProtectedRoute path="/offers" exact middleware={[Authed, Verified]} module="documents" component={Documents} /> */}
				<ProtectedRoute path="/imports/:id" exact middleware={[Authed, Verified]} module="documents" component={Import} />

				<ProtectedRoute path="/payorders" exact middleware={[Authed, Verified]} module="documents" component={PayOrders} />
				<ProtectedRoute path="/payorders/add" exact middleware={[Authed, Verified]} permission="documents:write" component={PayOrder} />
				<ProtectedRoute path="/payorders/edit/:id" exact middleware={[Authed, Verified]} permission="documents:write" component={PayOrder} />

				<ProtectedRoute path="/cashorders" exact middleware={[Authed, Verified]} module="documents" component={CashOrders} />
				<ProtectedRoute path="/cashorders/add" exact middleware={[Authed, Verified]} permission="documents:write" component={CashOrder} />
				<ProtectedRoute path="/cashorders/edit/:id" exact middleware={[Authed, Verified]} permission="documents:write" component={CashOrder} />

				<ProtectedRoute path="/integrations/oauth/callback" exact middleware={Authed} component={IntegrationsOAuthCallback} />
				<ProtectedRoute path="/integrations/slack/connect" exact middleware={Authed} component={IntegrationsConnectSlack} />
				<ProtectedRoute path="/integrations/slack/callback" exact middleware={Authed} component={IntegrationsSlackCallback} />
				<ProtectedRoute path="/integrations/messenger/connect" exact middleware={Authed} component={IntegrationsConnectMessenger} />
				<ProtectedRoute path="/integrations/success" exact middleware={Authed} component={IntegrationsSuccess} />
				<ProtectedRoute path="/integrations/fail" exact middleware={Authed} component={IntegrationsFail} />

				<ProtectedRoute path="/finances/:tab" exact middleware={[Authed, Verified]} component={Finances} />

				<ProtectedRoute path="/reports" exact middleware={[Authed, Verified]} component={Reports} />
				<ProtectedRoute path="/reports/storage/amounts-by-depots" exact middleware={[Authed, Verified]} component={ReportStorageAmountsByDepots} />

				<ProtectedRoute path="/payments/complete" exact component={PaymentComplete} />
				<ProtectedRoute path="/payments/cancel" exact component={PaymentCancel} />

				<ProtectedRoute path="/projects" exact middleware={[Authed, Verified]} component={Projects} />
				<ProtectedRoute path="/projects/types" exact middleware={[Authed, Verified]} component={ProjectsTypes} />
				<ProtectedRoute path="/projects/teams" exact middleware={[Authed, Verified]} component={ProjectsTeams} />

				<ProtectedRoute path="/machines" exact middleware={[Authed, Verified]} component={Machines} />
				<ProtectedRoute path="/machines/types" exact middleware={[Authed, Verified]} component={MachinesTypes} />

				<ProtectedRoute path="/tasks" exact middleware={[Authed, Verified]} component={Tasks} />
				<ProtectedRoute path="/tasks/types" exact middleware={[Authed, Verified]} component={TasksTypes} />
				<ProtectedRoute path="/tasks/statuses" exact middleware={[Authed, Verified]} component={TasksStatuses} />

				<ProtectedRoute path="/settings/:page/:inner?" exact middleware={[Authed, Verified]} component={Settings} />

				<Route path="/company/delete/success" exact component={CompanyDeleteRequestSuccess} />
				<Route path="/company/delete/fail" exact component={CompanyDeleteRequestFail} />

				<ProtectedRoute path="/categories" exact middleware={[Authed, Verified]} component={Categories} />
				<ProtectedRoute path="/articles" exact middleware={[Authed, Verified]} component={Articles} />
				<ProtectedRoute path="/articles-mass-update" exact middleware={[Authed, Verified]} component={ArticlesMassUpdate} />
				<ProtectedRoute path="/articles-print-list" exact middleware={[Authed, Verified]} component={ArticlesPrintList} />

				<ProtectedRoute path="/storage/loads" exact middleware={[Authed, Verified]} component={StoreLoads} />
				{/* <ProtectedRoute path="/storage/loads/new" exact middleware={[Authed, Verified]} component={StoreLoad} />
				<ProtectedRoute path="/storage/loads/:id/edit" exact middleware={[Authed, Verified]} component={StoreLoad} /> */}

				<ProtectedRoute path="/storage/moves" exact middleware={[Authed, Verified]} component={StoreMoves} />
				{/* <ProtectedRoute path="/storage/moves/new" exact middleware={[Authed, Verified]} component={StoreMove} />
				<ProtectedRoute path="/storage/moves/:id/edit" exact middleware={[Authed, Verified]} component={StoreMove} /> */}

				<ProtectedRoute path="/storage/outs" exact middleware={[Authed, Verified]} component={StoreOuts} />
				<ProtectedRoute path="/storage/outs/new" exact middleware={[Authed, Verified]} component={StoreOut} />
				<ProtectedRoute path="/storage/outs/:id/edit" exact middleware={[Authed, Verified]} component={StoreOut} />

				<ProtectedRoute path="/marketing/calendar" exact middleware={[Authed, Verified]} component={MarketingCalendar} />
				<ProtectedRoute path="/marketing/archive" exact middleware={[Authed, Verified]} component={MarketingArchive} />
				<ProtectedRoute path="/marketing/deals" exact middleware={[Authed, Verified]} component={MarketingDeals} />

				<ProtectedRoute path="/communication" exact middleware={[Authed, Verified]} component={Communication} />

				<ProtectedRoute path="/storage/revisions" exact middleware={[Authed, Verified]} component={Revisions} />
				<ProtectedRoute path="/storage/revisions/:id/edit" exact middleware={[Authed, Verified]} component={Revision} />

				<ProtectedRoute path="/accounts" exact middleware={[Authed, Verified]} component={Accounts} />
				<ProtectedRoute path="/accounts/new" exact middleware={[Authed, Verified]} component={Account} />
				<ProtectedRoute path="/accounts/:id/edit" exact middleware={[Authed, Verified]} component={Account} />

				<ProtectedRoute path="/orders" exact middleware={[Authed, Verified]} component={Orders} />

				<ProtectedRoute path="/sales/all" exact middleware={[Authed, Verified]} component={AllSales} />
				<ProtectedRoute path="/sales/complaints" exact middleware={[Authed, Verified]} component={Complaints} />
				<ProtectedRoute path="/sales/recommendations" exact middleware={[Authed, Verified]} component={Recommendations} />

				<ProtectedRoute path="/sandbox" exact middleware={[Authed, Verified]} component={Sandbox} />

				<PublicPreviewProvider>
					<Route path="/inv/:hash" exact component={PublicDocumentPreview} />
				</PublicPreviewProvider>

				<Route path="/unauthorized" component={e401} />

				<Route path="*" component={e404} />
			</Switch>
		)} />
	)

	const common = (
		<>
			{/* <SnackBar
				severity="success"
				open={app.state.messages.success.show}
				message={app.state.messages.success.message}
			/>
			<SnackBar
				severity="info"
				open={app.state.messages.info.show}
				message={app.state.messages.info.message}
			/>
			<SnackBar
				severity="error"
				open={app.state.messages.error.show}
				message={app.state.messages.error.message}
			/>
			{auth.isLogged() &&
				<>
					<SharedModals />
					<Update ref={updateModalRef} versionCode={VERSION_CODE} />
				</>
			} */}
		</>
	)

	return (
		<>
			{routes}
			{common}
			{auth.isLogged() ?
				<Footer />
				:
				<>
				</>
			}
		</>
	)
}

export default App

