import { useState, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap';
import { Pencil, Envelope } from 'react-bootstrap-icons';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import PriceValue from 'components/partials/PriceValue';
import { useHistory } from 'react-router-dom';
import { isNumeric } from 'helpers/Price';
import Refs from 'Refs';
import { useAppContext } from 'providers/App';
import { useAuthDataContext } from 'providers/Auth';
import Rows from './Rows';
import { Button } from '@material-ui/core';

function Index(props) {

    const { show, hide } = props;
    const { state, setState } = props;

    const app = useAppContext();
    const auth = useAuthDataContext();
    const navigate = useHistory();

    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        Api.get('store/orders/statuses').then(res => {
            setStatuses(res.data);
        });
    }, []);

    const getAddressName = address => {
        if (!address) {
            return '';
        }

        let name = [];

        if (address.delivery_type === 'address') {
            name = [
                address.dest_name_full,
                address.address,
                address.description,
                address.country_name,
            ];
        }

        if (address.delivery_type === 'office') {
            name = [
                address.dest_name_full,
                address.office_name,
            ];
        }

        if (address.delivery_type === 'store') {
            name = [
                address?.store?.translation?.name || address?.store?.name,
            ];
        }

        return name.filter(p => p).join(', ');
    }

    const getExtendedAddressName = address => {
        if (!address) {
            return '';
        }

        let map = {
            address: 'Адрес на клиент',
            office: 'Офис на куриер',
            store: 'Търговски обект'
        };

        let type = map[address.delivery_type];

        if (type) {
            return type + ': ' + getAddressName(address);
        }

        return '';
    }

    const handleChangeStatus = (id) => {
        let modal = Refs.getInstance().getRef('question');

        if (!modal) {
            return;
        }

        props.hide(() => {
            modal.open('Сигурни ли сте, че искате да промените статуса на заявката?');

            modal.onSuccess(() => {
                Api.post('store/orders/set-status', {
                    id: state.data.id,
                    status_id: id,
                }).then(res => {
                    app.showSuccess();

                    setState(prev => ({
                        ...prev,
                        data: res.data,
                    }));
                });
            });
        });

        modal.onClose(() => {
            setTimeout(() => {
                props.show();
            }, 1000);
        })
    }

    const handleSetDepotsData = () => {
        let modal = Refs.getInstance().getRef('question');

        if (!modal) {
            return;
        }

        props.hide(() => {
            modal.open('Сигурни ли сте, че искате да преразпределите количествата?');

            modal.onSuccess(() => {
                Api.post('store/orders/set-depots-data', {
                    id: state.data.id,
                }).then(res => {
                    app.showSuccess();

                    props.show();
                });
            });
        });

        modal.onClose(() => {
            setTimeout(() => {
                props.show();
            }, 100);
        })
    }

    const showClient = () => {
        props.showClient(state.data.client_id);
    }

    const showArticle = id => {
        props.showArticle(id);
    }

    const handleEdit = () => {
        navigate.push('/orders/' + state.data.id + '/edit');
    }

    const sendMail = () => {
        props.sendMail(state.data);
    }

    const sendReceipt = () => {
        props.sendReceipt(state.data);
    }

    const handleShowSmartUcfStatus = () => {
        props.showSmartUcfStatus(state.data.id);
    }

    const handleNewAccount = () => {
        navigate.push('/accounts/new?from_order_id=' + state.data.id);
    }

    const handleNewInvoice = () => {
        navigate.push('/invoices/new?from_order_id=' + state.data.id);
    }

    return (
        <>
            {state.loading
                ?
                <Loader />
                :
                <>
                    <div className="preview-article-home">
                        <div className="options-bar">
                            {/* {state.data.allow_edit &&
                                <Button className="cancel" onClick={() => handleEdit()}>
                                    <Pencil /> Редактиране
                                </Button>
                            } */}
                            {/* <Button className="cancel" onClick={() => sendMail()}>
                                <Envelope /> Изпращане
                            </Button> */}
                            {/* {state.data.accounts_count > 0 &&
                                <Button className="cancel" onClick={() => sendReceipt()}>
                                    <Envelope /> Електронна бележка
                                </Button>
                            } */}

                            <Button className="cancel" onClick={() => handleNewAccount()} disabled={state.data.allow_account === false} >
                                Нова продажба
                            </Button>
                            {/* <Button className="cancel" onClick={() => handleNewInvoice()} disabled={state.data.allow_invoice === false}>
                                Фактура
                            </Button> */}

                            {state.data.next_status &&
                                <Button className="cancel" onClick={() => handleChangeStatus(state.data.next_status.id)} disabled={state.data.allow_status_change === false}>
                                    Направи "<i>{state.data.next_status?.translation?.name || state.data.next_status.name}</i>"
                                </Button>
                            }
                            {/* <Button className="cancel" onClick={() => handleSetDepotsData()}>
                                Преразпредели количества по ТО
                            </Button> */}
                            {/* <Button size="sm" variant="secondary" onClick={() => { }}>
                            <Pencil /> Принтирай етикет (TODO)
                        </Button>
                        <Button size="sm" variant="secondary" onClick={() => { }}>
                            <Pencil /> Покажи етикет (TODO)
                        </Button> */}
                            {state.data.paymentmethod?.is_smart_ucf &&
                                <Button size="sm" variant="secondary" onClick={() => handleShowSmartUcfStatus()}>
                                    Статус на заявката за кредитиране
                                </Button>
                            }
                        </div>

                        <div className="row">
                            <div className="col">
                                <h6>
                                    Описание на поръчката
                                </h6>
                                <div className="field">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="th" style={{ width: '13.66666%' }}>
                                                    ID
                                                </td>
                                                <td style={{ width: '19.66666%' }}>
                                                    {state.data.id}
                                                </td>
                                                <td className="th" style={{ width: '13.66666%' }}>
                                                    Статус
                                                </td>
                                                {/* <td onClick={() => handleChangeStatus(state.data.next_status?.id)} style={{ backgroundColor: state.data.status?.color || '#fff', cursor: 'pointer' }}>
                                    <span style={{ color: state.data.status?.color || '#fff', mixBlendMode: 'difference' }}>
                                        {state.data?.status?.translation?.name || state.data?.status?.name}
                                    </span>
                                </td> */}
                                                <td style={{ width: '19.66666%', padding: 0 }}>
                                                    <select
                                                        value={state.data.status_id || ''}
                                                        onChange={e => handleChangeStatus(e.target.value)}
                                                        disabled={state.data.allow_status_change === false}
                                                        style={{
                                                            minWidth: '100px',
                                                            // height: '40px',
                                                            border: 'none',
                                                            outline: 'none'
                                                        }}
                                                    >
                                                        <option value="" disabled></option>
                                                        {statuses.map(status =>
                                                            <option key={status.id} value={status.id}>{status?.translation?.name || status?.name}</option>
                                                        )}
                                                    </select>
                                                </td>
                                                <td className="th" style={{ width: '13.66666%' }}>
                                                    Създал
                                                </td>
                                                <td style={{ width: '19.66666%' }}>
                                                    {state.data?.creator?.username}
                                                </td>
                                            </tr>
                                            <tr>

                                                <td className="th">
                                                    Плащане
                                                </td>
                                                <td>
                                                    {state.data?.paymentmethod?.translation?.name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Клиент
                                                </td>
                                                <td colSpan={3} style={{ cursor: 'pointer' }} onClick={showClient}>
                                                    {state.data.client_name || state.data?.client?.name} {isNumeric(state.data?.client?.discount) ? '(' + state.data?.client?.discount + '%)' : ''}
                                                </td>
                                                <td className="th">
                                                    Обработва се в
                                                </td>
                                                <td>
                                                    {state.data.fromstore?.translation?.name || state.data.fromstore?.name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Създадена на
                                                </td>
                                                <td>
                                                    {state.data.created_at ? moment(state.data.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                </td>
                                                <td className="th">
                                                    Актуализирано на
                                                </td>
                                                <td>
                                                    {state.data.updated_at ? moment(state.data.updated_at).format('DD.MM.YYYY HH:mm') : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Лице за контакти
                                                </td>
                                                <td>
                                                    {state.data.contact_name}
                                                </td>
                                                <td className="th">
                                                    Телефон
                                                </td>
                                                <td>
                                                    {state.data.client_tel}
                                                </td>
                                                <td className="th">
                                                    Е-поща
                                                </td>
                                                <td>
                                                    {state.data.client_email}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Доставка до
                                                </td>
                                                <td>
                                                    {state.data.deliverystore ? (state.data.deliverystore?.translation?.name || state.data.deliverystore.name) : (state.data.has_store_pickup ? 'Взимане от магазин' : 'Адрес на клиента')}
                                                </td>
                                                <td className="th">
                                                    Товарителница №
                                                </td>
                                                <td>
                                                    {state.data.tracking_number}
                                                </td>
                                                <td className="th">
                                                    Спедитор
                                                </td>
                                                <td>
                                                    {state.data.speditor?.name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    С фактура
                                                </td>
                                                <td>
                                                    {state.data.with_invoice ? 'ДА' : 'НЕ'}
                                                </td>
                                                <td className="th">
                                                    Данни за доставка
                                                </td>
                                                <td colSpan={3}>
                                                    {getExtendedAddressName(state.data.deliveryaddress)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Клиентски бележки
                                                </td>
                                                <td colSpan={5}>
                                                    {state.data.public_notes}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Описание
                                                </td>
                                                <td colSpan={5}>
                                                    {state.data.description}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Допълнителни
                                                </td>
                                                <td colSpan={5}>
                                                    {state.data.extra_details}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Общо
                                                </td>
                                                <td>
                                                    {state.data?.currency?.prefix} <PriceValue>
                                                        {state.data.total_sum}
                                                    </PriceValue> {state.data?.currency?.sufix}
                                                </td>
                                                <td className="th">
                                                    Отстъпка
                                                </td>
                                                <td>
                                                    {state.data?.currency?.prefix} <PriceValue>
                                                        {state.data.total_real - state.data.total_sum}
                                                    </PriceValue> {state.data?.currency?.sufix}
                                                </td>
                                                <td className="th">
                                                    Крайна цена
                                                </td>
                                                <td>
                                                    {state.data?.currency?.prefix} <PriceValue>
                                                        {state.data.total_real}
                                                    </PriceValue> {state.data?.currency?.sufix}
                                                </td>
                                            </tr>
                                            {/* <tr>
                                                <td className="th">
                                                    IP адрес
                                                </td>
                                                <td colSpan={5}>
                                                    {state.data.ip_address}
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <h6>
                                    Артикули
                                </h6>
                                <div className="field">
                                    <Rows
                                        id={state.data.id}
                                        show={show}
                                        hide={hide}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Index;