import { useEffect, useState } from "react"

//helpers
import Api from "helpers/Api"

function useFetchSuggestedNumbers({
    companyId,
    companyAddressId,
    typeId,
}) {
    const [suggestedNumbers, setSuggestedNumbers] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        if (!companyId || !companyAddressId || !typeId) return
        
        _fetchSuggestedNumbers()
    }, [companyId, companyAddressId, typeId])

    function _fetchSuggestedNumbers() {
        Api.get(`documents/next-numbers?company_id=${companyId}&company_address_id=${companyAddressId}&type_id=${typeId}`)
            .then(res => {
                setSuggestedNumbers(res.data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    function getSuggestedNumbers() {
        return suggestedNumbers
    }

    return {
        getSuggestedNumbers,
        loadingSuggestedNumbers: isLoading,
    }
}

export default useFetchSuggestedNumbers